import axios from 'axios';
import constants from '../constants';
import { useEffect, useState } from 'react';

export const fetchInstance = axios.create({
  baseURL:
    process.env.REACT_APP_NODE_ENV === 'production'
      ? process.env.REACT_APP_PROD_HEALTH_RECORD_PROXY_URL
      : process.env.REACT_APP_HEALTH_RECORD_PROXY_URL,
});
type GetCustomHomeProgramPayload = { clientId: string; homeProgramId: string };

export interface AttachmentHomeProgram {
  id: string;
  filename: string;
  url: string;
}

export type GetCustomHomeProgramResponse = {
  clientID: string;
  editedAt: string;
  customHomeProgramID: string;
  createdAt: string;
  description: string;
  title: string;
  attachment?: AttachmentHomeProgram;
};
const getCustomHomeProgramById = async (payload: GetCustomHomeProgramPayload) => {
  const { clientId, homeProgramId } = payload;
  return fetchInstance.get(`/home-programs/custom/${clientId}/${homeProgramId}`).then(res => res.data);
};

export const useGetCustomHomeProgramById = (payload: GetCustomHomeProgramPayload) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<GetCustomHomeProgramResponse | null>(null);
  const [error, setError] = useState('');

  const fetchCustomHomeProgram = () => {
    getCustomHomeProgramById(payload)
      .then(res => setResponse(res))
      .catch(err => {
        if (err?.response?.status === 404) setError(constants.ERRORS.HOME_PROGRAM_NOT_FOUND);
        else setError(constants.ERRORS.UNKNOW_ERROR);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchCustomHomeProgram();
  }, []);

  return { loading, response, error };
};

export default useGetCustomHomeProgramById;
