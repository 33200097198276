import React, { useEffect } from "react";
import {
  Loader,
  Textarea,
  Input,
  Button,
  Label,
} from "@expressable/ui-library";
import { useToasts } from "react-toast-notifications";
import { useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import hippaLogo from "../hipaa-logo.png";
import expressableLogo from "../assets/logo.png";
import download from "downloadjs";
import uploadLogo from "../assets/upload-logo.svg";
import downloadLogo from "../assets/download-logo.svg";
import { useGetCustomHomeProgramById } from "hooks/use-get-custom-home-program";
import { useCustomHomeworkSubmission } from "hooks/use-custom-homework-submission";
import { FileViewerRow } from "components/FileViewerRow";
import mixpanel from "mixpanel-browser";
import LogRocket from "logrocket";

const HomeworkStyled = styled.div`
  ul {
    list-style: none;
  }
  ul li {
    display: flex;
    line-height: 2;
  }
  ul li::before {
    content: "●";
    color: #5850ec;
    font-weight: bold;
    display: inline-block;
    width: 20px;
    line-height: 2.2;
    flex: none;
    font-size: 14px;
  }
  ul li p {
    display: inline-block;
  }
  .attachment {
    display: flex;
    flex-direction: column;
    font-size: 14px;

    .title {
      color: #6a6c6e;
      margin-bottom: 15px;
    }

    .download {
      display: flex;
      flex-direction: row;
      color: #5850ec;
      cursor: pointer;
      align-items: center;
      font-weight: 600;

      .icon {
        margin-right: 10px;
        width: 24px;
        height: 24px;
      }
    }
  }
  @media (max-width: 768px) {
    //background-color: #5850ec;
  }
`;
const BackgroundImage = styled.div`
  background-image: url(https://ik.imagekit.io/expressable/post-schedule-page/Group_55_QOfFVioGl1.png);
  background-size: cover;
  background-position-y: 100%;
  background-repeat: no-repeat;
  display: flex;
  position: relative;
  @media (max-width: 768px) {
    background-image: none;
    background-color: #5850ec;
    display: block;
  }
`;

type TParams = { clientId: string; homeworkId: string };
export type IHomeWorkSubmissionForm = {
  success: string;
  difficulty: string;
  file?: FileList;
};

function Homework() {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
  } = useForm<IHomeWorkSubmissionForm>({
    mode: "onChange",
    defaultValues: {
      success: "",
      difficulty: "",
    },
  });

  const history = useHistory();
  const { homeworkId, clientId } = useParams<TParams>();

  useEffect(() => {
    const token = process.env.REACT_APP_MIXPANEL_TOKEN;
    if (!token) throw new Error("No Mixpanel token found");
    mixpanel.init(token, {
      debug: true,
    });
    mixpanel.track("Viewed Home Program", {
      homeProgramId: homeworkId,
      homeworkType: "custom",
    });
  }, []);

  const {
    loading: isLoadingHomework,
    error: getCustomHomeProgramError,
    response,
  } = useGetCustomHomeProgramById({
    clientId: clientId,
    homeProgramId: homeworkId,
  });
  const { addToast } = useToasts();
  const fileWatcher = useWatch({ control, name: "file" });

  const {
    loading: isSubmittingHomework,
    error: submissionError,
    submitCustomHomework,
    submitted,
  } = useCustomHomeworkSubmission();

  useEffect(() => {
    if (submissionError)
      addToast(submissionError, { appearance: "error", autoDismiss: true });
    LogRocket.captureException(submissionError as unknown as Error);
    if (submitted) history.push("/thankyou");
  }, [submissionError, isSubmittingHomework, submitted]);

  if (isLoadingHomework) {
    return (
      <div data-testid="loader" className="flex-1 text-center py-20">
        <Loader type="ring" />
      </div>
    );
  }

  if (clientId) {
    LogRocket.identify(clientId);
  }

  if (getCustomHomeProgramError) {
    return (
      <BackgroundImage className="md:px-28 sm:px-20">
        <div className="md:py-8 py-4 sm: w-11/12 m-auto md:m-0 md:w-1/2">
          <Link className="w-32" to="/">
            <img
              className="w-32"
              src="https://ik.imagekit.io/expressable/expressable-white_O49K43BeY.svg"
              alt="Expressable Logo"
            />
          </Link>
          <p className="sm:w-full md:text-4xl text-2xl md:w-3/5 mb-10 text-white font-domine md:py-28 leading-snug mb-0 pt-12 pb-4">
            {getCustomHomeProgramError}
          </p>
        </div>
      </BackgroundImage>
    );
  }

  const onSubmitHomework = async (dataForm: IHomeWorkSubmissionForm) =>
    await submitCustomHomework(dataForm, response);

  return (
    <HomeworkStyled className="font-poppins leading-8">
      <Helmet defaultTitle={response?.title} />
      <BackgroundImage className="md:px-28 sm:px-20">
        <div className="md:py-8 py-4 sm: w-11/12 m-auto md:m-0 md:w-1/2 w-11/12">
          <Link className="w-32" to="/">
            <img
              className="w-32"
              src={expressableLogo}
              alt="Expressable Logo"
            />
          </Link>
          <p className="sm:w-full md:text-4xl text-2xl md:w-3/5 mb-10 text-white font-domine md:py-28 leading-snug pt-12 pb-4  leading-normal tracking-normal">
            {response?.title}
          </p>
        </div>
      </BackgroundImage>
      <div className="md:px-28 sm:px-20 max-w-7xl">
        <div className="md:px-0 sm:px-8 px-8 flex text-gray-800 w-full md:w-full sm:bg-white bg-white md:m-0 m-auto homework">
          <div className="inline-block md:w-1/2 w-full">
            <div>
              <div className="pt-24">
                <div
                  data-testid="custom-homework-head"
                  className="font-semibold text-2xl mb-4  leading-normal tracking-normal"
                >
                  Activity Overview
                </div>
                <p data-testid="custom-homework-description" className="mb-8">
                  {response?.description}
                </p>
              </div>
              {response?.attachment && (
                <div className="attachment" data-testid="attachment">
                  <div className="title">YOUR HOMEWORK WORKSHEETS</div>
                  <div
                    className="download"
                    onClick={() => download(response!.attachment!.url)}
                  >
                    <img className="icon" src={downloadLogo} />
                    <div>Download [{response!.attachment!.filename}]</div>
                  </div>
                </div>
              )}
              <hr className="my-10" />
            </div>
            <form onSubmit={handleSubmit(onSubmitHomework)}>
              <div
                data-testid="custom-homework-first-input-head"
                className="flex mt-10 mb-2"
              >
                <h4 className="text-sm font-semibold">{`Describe any success when practicing this activity.`}</h4>
              </div>
              <Textarea
                {...register("success", {
                  required: "Please, fill out this field",
                })}
                data-testid="success"
                className="w-full"
                error={errors.success}
                rows={4}
              />

              <div
                data-testid="custom-homework-second-input-head"
                className="flex mt-10 mb-2"
              >
                <h4 className="text-sm font-semibold">
                  What was difficult when practicing this activity?
                </h4>
              </div>
              <Textarea
                {...register("difficulty", {
                  required: "Please, fill out this field",
                })}
                data-testid="difficulty"
                className="w-full"
                rows={4}
                error={errors.difficulty}
              />

              <div
                data-testid="custom-homework-third-input-head"
                className="flex mt-10 mb-2"
              >
                <h4 className="text-sm font-semibold">{`Please attach a file of you participating in this activity - like a video or audio clip (optional)`}</h4>
              </div>
              <div className="flex w-full items-center mb-2">
                <Label className="w-full flex flex-col items-center px-4 py-6 bg-white text-indigo-500 rounded-lg tracking-wide border border-dashed border-gray-default cursor-pointer ">
                  <img
                    src={uploadLogo}
                    alt="upload logo"
                    className="h-12 w-12"
                  />
                  <span className="mt-2 text-base leading-normal">
                    Upload File
                  </span>
                  <Input
                    {...register("file")}
                    accept="image/jpeg, image/png, image/jpg, audio/aac, audio/amr, audio/mpeg, audio/x-m4a, video/mp4, video/mpeg, video/quicktime, video/x-msvideo, video/x-ms-wmv, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    type="file"
                    name="file"
                    data-testid="file"
                    className="w-full mb-2 hidden"
                  />
                </Label>
              </div>
              <h4
                data-testid="custom-homework-attachment-sub-head"
                className="text-sm text-gray-400 mb-12"
              >
                This attachment will only be viewed by your speech therapist
              </h4>
              {fileWatcher?.length && (
                <FileViewerRow
                  fileWatcher={fileWatcher}
                  onRemoveFile={() => setValue("file", undefined)}
                />
              )}

              <div className="flex w-full flex-col lg:flex-row">
                <div className="flex w-full lg:w-1/2">
                  <Button
                    disabled={!isValid || isSubmittingHomework}
                    loading={isSubmittingHomework}
                    variant="primary"
                    data-testid="submit-customhomework"
                    className="bg-indigo-600  text-white disabled:bg-indigo-200 mb-10 flex-1 leading-normal tracking-normal"
                    type="submit"
                  >
                    Submit To Your Therapist
                  </Button>
                </div>

                <div
                  data-testid="custom-homework-hipaa"
                  className="flex lg:w-1/2 sm:ml-3 justify-center  w-full md:justify-end"
                >
                  <img
                    src={hippaLogo}
                    alt="HIPAA"
                    style={{ width: "104px", height: "37px" }}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </HomeworkStyled>
  );
}
export default Homework;
