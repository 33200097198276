import React from 'react';

function Home() {
  return (
    <div className=" w-full h-full bg-indigo-600 flex items-center justify-center min-h-screen">
      <img
        className="w-1/2 h-auto"
        src="https://ik.imagekit.io/expressable/expressable-white_O49K43BeY.svg"
        alt="Expressable Logo"
      />
    </div>
  );
}
export default Home;
