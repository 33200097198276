import { useQuery } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import axios from 'axios';

const http = dynamicHttp('s3-storage');

interface IFile {
  file: string;
  link: string;
}

const getFiles = async (id: string): Promise<IFile[]> => {
  const { data } = await http.get<IFile[]>(`/files/${id}`);
  return data;
};

export function useFiles(id: string) {
  return useQuery<IFile[], Error>(['client-files', id], () => getFiles(id));
}

interface GenerateUploadUrlPayload {
  clientId?: string;
  mime?: string;
  fileName?: string;
  fileExtension?: string;
}
export const generateUploadUrl = (payload: GenerateUploadUrlPayload) => {
  const { clientId, mime, fileName, fileExtension } = payload;
  return axios
    .put(
      `${
        process.env.REACT_APP_NODE_ENV === 'production'
          ? process.env.REACT_APP_PROD_FILE_STORAGE_API_URL
          : process.env.REACT_APP_FILE_STORAGE_API_URL
      }/files/${clientId}`,
      {
        mime,
        fileName,
        fileExtension,
      },
    )
    .then(res => res.data);
};

interface UploadFilePayload {
  url: string;
  mime: string;
  buffer: Buffer;
  clientID: string;
}

export const uploadFile = async (payload: UploadFilePayload) => {
  const { url, mime, buffer } = payload;
  return await axios.put(url, buffer, {
    headers: {
      'Content-Type': mime,
      'Content-Length': buffer.length,
    },
  });
};
