import { IHomeWorkSubmissionForm } from 'pages/custom-homework';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { generateUploadUrl, uploadFile } from './use-files';
import { GetCustomHomeProgramResponse } from './use-get-custom-home-program';
import { IHomeworkSubmission, sendHomeworkSubmission } from './use-homework-submission';
import constants from '../constants';

export const useCustomHomeworkSubmission = (type: 'custom' | 'contentful-homework' = 'custom') => {
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const { homeworkId, clientId } = useParams<never>();

  const submitCustomHomework = async (
    homework: IHomeWorkSubmissionForm,
    response: GetCustomHomeProgramResponse | null,
  ) => {
    try {
      setIsLoading(true);
      setSubmitted(false);
      const homeworkSubmission: IHomeworkSubmission = {
        clientId: clientId,
        type: type,
        id: homeworkId,
        title: response?.title ?? '',
        description: response?.description ?? '',
        success: homework.success,
        difficulty: homework.difficulty,
      };
      // if we have a file, we create a new object property for file
      if (homework?.file?.length) {
        const fileToUpload = homework?.file![0];
        const fileReader = new FileReader();
        fileReader.onload = async fileLoadedEvent => {
          const srcData = fileLoadedEvent.target!.result;
          const [mimeData, file] = (srcData as string).split(';');
          const [, mime] = mimeData.split(':');
          const fileName = fileToUpload.name;

          const generateUploadUrlPayload = {
            clientId: clientId,
            mime,
            fileName,
            fileExtension: fileToUpload.name.split('.').pop(),
          };
          const { url } = await generateUploadUrl(generateUploadUrlPayload);
          const fileData = file.substr(7, file.length);
          const fileBuffer = Buffer.from(fileData, 'base64');
          await uploadFile({
            url: url,
            mime: mime,
            buffer: fileBuffer,
            clientID: clientId,
          });

          homeworkSubmission.file = {
            mime: mime,
            fileName: fileName,
            fileExtension: fileToUpload.name.split('.').pop() ?? 'no-name',
          };

          const response = await sendHomeworkSubmission(homeworkSubmission);
          if (response?.clientID) {
            setSubmitted(true);
            setIsLoading(false);
          }
        };

        fileReader.readAsDataURL(fileToUpload);
      } else {
        await sendHomeworkSubmission(homeworkSubmission);
        if (response?.clientID) {
          setSubmitted(true);
          setIsLoading(false);
        }
      }
    } catch (err) {
      console.error(err);
      setError(constants.ERRORS.UNKNOW_ERROR);
      setSubmitted(false);
    }
  };

  return { loading, error, submitCustomHomework, submitted };
};
