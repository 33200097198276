import axios from 'axios';

export interface IHomeworkSubmission {
  clientId: string;
  type: 'contentful-homework' | 'custom';
  title: string;
  description: string;
  success: string;
  difficulty: string;
  id: string;
  materials?: { id: string; name: string };
  file?: {
    mime: string;
    fileName: string;
    fileExtension: string;
  };
}

export const sendHomeworkSubmission = async (homeworkSubmission: IHomeworkSubmission) => {
  const { clientId, ...homeworkSubmissionPayload } = homeworkSubmission;
  return axios
    .post(
      `${
        process.env.REACT_APP_NODE_ENV === 'production'
          ? process.env.REACT_APP_PROD_HEALTH_RECORD_PROXY_URL
          : process.env.REACT_APP_HEALTH_RECORD_PROXY_URL
      }/home-programs/submission/${clientId}`,
      homeworkSubmissionPayload,
    )
    .then(res => res.data);
};

export const useHomeworkSubmission = () => {
  return { sendHomeworkSubmission };
};
