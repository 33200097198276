import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import expressableLogo from '../assets/logo.png';
import Mixpanel from 'mixpanel-browser';
const BackgroundImage = styled.div`
  background-image: url(https://ik.imagekit.io/expressable/post-schedule-page/Group_55_QOfFVioGl1.png);
  image-rendering: crisp-edges;

  background-size: cover;
  background-position-y: 100%;
  background-repeat: no-repeat;
  display: flex;
  position: relative;
  @media (max-width: 768px) {
    background-image: none;
    background-color: #5850ec;
    display: block;
  }
`;

function Thankyou() {

  useEffect(() => {
    const token = process.env.REACT_APP_MIXPANEL_TOKEN;
    if(!token) throw new Error('No Mixpanel token found');

    Mixpanel.init(token); 
    Mixpanel.track('Completed Home Program', {});
  }, [])
  return (
    <>
      <Helmet defaultTitle="Thank you!" />
      <BackgroundImage className="h-full md:px-28 sm:px-20">
        <div className="md:py-8 py-4 m-auto  md:w-7/10 w-11/12">
          <Link className="w-32" to="/">
            <img className="w-32" src={expressableLogo} alt="Expressable Logo" />
          </Link>
          <div className="flex-col sm:flex-row">
            <p className=" md:text-40px text-2xl md:w-4/5 text-white font-domine leading-normal pt-12 pb-4 font-normal tracking-normal mt-12 mb-6">
              Great job! You completed another Expressable Academy homework!
            </p>
          </div>
          <div className="flex-col sm:flex-row">
            <p className="md:text-16px text-white font-normal  md:w-3/4 font-domine md:py-20 leading-normal tracking-normal">
              Your therapist will review this homework submission prior to your next session. The more speech therapy
              practice that happens at home, the more progress you&apos;ll make towards your communication goal.
            </p>
          </div>
        </div>

        <div className="hidden lg:flex w-full items-end sm:flex-row-reverse md:flex-row mt-32 md:mt-64 justify-end">
          <img
            src="https://ik.imagekit.io/expressable/EXP-web-illustrations_homework-parent-child-desktop-2x_2_KWcZ-CfIw.png?ik-sdk-version=javascript-1.4.3&updatedAt=1646090110018"
            alt="Expressable Logo"
            className="w-4/5"
          />
        </div>
      </BackgroundImage>
    </>
  );
}
export default Thankyou;
