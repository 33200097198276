import { useState, useEffect } from 'react';
import { createClient, Entry } from 'contentful';
import { Document } from '@contentful/rich-text-types';
import constants from '../constants';
// Data from Contentful
// Read-only access to content-delivery API. Not sensitive
const SPACE_ID = 'o4loakmn752b';
const ACCESS_TOKEN = '4reeeSiwbJltOiU0S6y6hgcj64DSZpL21FjNAxKwOr8';

export interface IResource {
  title: string;
  description: string;
  url: string;
  category: string;
  aboutThisActivity: string;
  activityGuidelinesStep1Title: string;
  ageRange: string;
  exampleQuestions: Document;
  homeworkGoals: Document;
  hwType: string;
  longTermGoal: string;
  shortTermGoal: string;
  treatmentArea: string;
  videoFile: [{ fields: { file: { url: string } } }];
  step1Title: string;
  step2Title: string;
  step3Title: string;
  step4Title: string;
  step5Title: string;
  whatToDoIfYourChildGetsStuck: Document;
  whatToLookForInChildsActions: Document;
  whatYoullNeed: Document;
  step1Description: Document;
  step2Description: string;
  step3Description: string;
  step4Description: string;
  step5Description: string;
  timeRequired: string;
  associatedMaterialsIfRequired: [
    {
      fields: {
        nameOfMaterial: string;
        uploadMaterial: [{ fields: { file: { url: string } }; sys: { id: string } }];
      };
    },
  ];
}

const client = createClient({
  space: SPACE_ID,
  accessToken: ACCESS_TOKEN,
});

const useContentfulData = (entry: string, params = {}): [Entry<IResource> | undefined, boolean, string] => {
  const [data, setData] = useState<Entry<IResource> | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState('');
  useEffect(() => {
    client
      .getEntry<IResource>(entry)
      .then(result => {
        setData(result);
        setLoading(false);
      })
      .catch(e => {
        console.error(e);
        setError(constants.ERRORS.HOME_PROGRAM_NOT_FOUND);
      })
      .finally(() => setLoading(false));
  }, []);

  return [data, loading, error];
};

export { useContentfulData };
