export function getFileSize(files: FileList, fileIndex = 0) {
  const fileSize = files[fileIndex].size.toString();

  const fileSizeInKb = (Number(fileSize) / 1024 / 1024).toFixed(2);
  const fileSizeInMB = (Math.round(Number(fileSize) / 1024) / 1000).toFixed(2);

  if (fileSize.length < 7) return `${fileSizeInKb}KB`;

  return `${fileSizeInMB}MB`;
}
