import React from 'react';
import { faPaperclip, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFileSize } from 'lib/utils/helpers';

interface FileViewerRowProps {
  fileWatcher: FileList;
  onRemoveFile: () => void;
}

// () => setValue('file', undefined);
export function FileViewerRow({ fileWatcher, onRemoveFile }: FileViewerRowProps) {
  return (
    <div className="flex flex-row mb-12">
      <div className="flex items-center flex-row truncate w-1/2">
        <FontAwesomeIcon className="text-gray-default text-2xl mr-3 " icon={faPaperclip} />
        <span className="mr-3 text-base leading-normal truncate ">{fileWatcher![0]?.name ?? ''}</span>
      </div>
      <div
        className="flex items-center flex-row text-gray-default w-1/2 justify-end cursor-pointer"
        title="Remove file"
        onClick={() => onRemoveFile()}
      >
        {<span>{getFileSize(fileWatcher) ?? ''}</span>}
        <FontAwesomeIcon className="text-indigo-500 text-2xl flex justify-end ml-3" icon={faTrashAlt} />
      </div>
    </div>
  );
}
