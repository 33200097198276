import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import Homework from 'pages/homework';
import CustomHomework from 'pages/custom-homework';
import Home from 'pages/home';
import Thankyou from 'pages/thank-you';
import LogRocket from "logrocket";

import MixPanel from 'mixpanel-browser';

function App() {

  useEffect(() => {
    const token = process.env.REACT_APP_MIXPANEL_TOKEN;
    if(!token) throw new Error('No Mixpanel token found');

    MixPanel.init(token, {
      debug: true
    }); 
  }, [])

  if (process.env.REACT_APP_NODE_ENV === "production") {
    LogRocket.init("m7fsxw/home-programs", {
      dom: {
        inputSanitizer: true,
        textSanitizer: true,
      },
      network: {
        responseSanitizer: (response) => {
          response.body = undefined;
          return response;
        },
        requestSanitizer: (request) => {
          request.body = undefined;
          return request;
        },
      },
    });
  }

  return (
    <ToastProvider placement="bottom-right" autoDismissTimeout={5000}>
      <div className="h-full w-full" data-testid="app">
        <Router>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/:clientId/:homeworkId" exact component={Homework} />
            <Route path="/:clientId/:homeworkId/custom" exact component={CustomHomework} />
            <Route path="/thankyou" exact component={Thankyou} />
          </Switch>
        </Router>
      </div>
    </ToastProvider>
  );
}
export default App;
