import React, { useEffect, useState } from "react";
import {
  Loader,
  Card,
  Textarea,
  Input,
  Button,
  Label,
} from "@expressable/ui-library";
import { useContentfulData } from "hooks/use-ContentfulData";
import { useToasts } from "react-toast-notifications";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import styled from "styled-components";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Link } from "react-router-dom";
import { useQuery } from "hooks/use-query";
import { useForm, useWatch } from "react-hook-form";
import hippaLogo from "../hipaa-logo.png";
import download from "downloadjs";
import uploadLogo from "../assets/upload-logo.svg";
import downloadLogo from "../assets/download-logo.svg";
import expressableLogo from "../assets/logo.png";
import { useExpressableHomeworkSubmission } from "hooks/use-expressable-homework-submission";
import { FileViewerRow } from "components/FileViewerRow";
import mixpanel from "mixpanel-browser";
import LogRocket from "logrocket";

const HomeworkStyled = styled.div`
  ul {
    list-style: none;
  }
  ul li {
    display: flex;
    line-height: 2;
  }
  ul li::before {
    content: "●";
    color: #5850ec;
    font-weight: bold;
    display: inline-block;
    width: 20px;
    line-height: 2.2;
    flex: none;
    font-size: 14px;
  }
  ul li p {
    display: inline-block;
  }
  @media (max-width: 768px) {
    //background-color: #5850ec;
  }
`;
const BackgroundImage = styled.div`
  background-image: url(https://ik.imagekit.io/expressable/post-schedule-page/Group_55_QOfFVioGl1.png);
  background-size: cover;
  background-position-y: 100%;
  background-repeat: no-repeat;
  display: flex;
  position: relative;
  @media (max-width: 768px) {
    background-image: none;
    background-color: #5850ec;
    display: block;
  }
`;
const WhatYoullNeedStyled = styled.div`
  ul li p::before {
    content: "";
    background-image: url(https://ik.imagekit.io/expressable/check_QIP6k4U4R.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    position: absolute;
    left: -40px;
    top: 0px;
    list-style: none;
  }

  ul li p {
    margin-left: 40px;
  }

  ul li::before {
    content: "";
    display: none;
  }

  p {
    position: relative;
    margin-bottom: 10px;
  }
`;

type TParams = { clientId: string; homeworkId: string };
type IHomeWorkSubmissionForm = {
  success: string;
  difficulty: string;
  file?: FileList;
};

function Homework(props: RouteComponentProps<TParams>) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm<IHomeWorkSubmissionForm>({
    defaultValues: {
      success: "",
      difficulty: "",
      file: undefined,
    },
    mode: "onChange",
  });
  const {
    params: { homeworkId, clientId },
  } = props.match;
  const [data, loadingData, error] = useContentfulData(homeworkId);
  const [showWhatToLook, setShowWhatToLook] = useState(false);
  const [showWhatToDo, setShowWhatToDo] = useState(false);
  const history = useHistory();
  const {
    loading: isSubmittingHomework,
    error: submissionError,
    submitExpressableHomework,
    submitted,
  } = useExpressableHomeworkSubmission();

  const { addToast } = useToasts();

  const query = useQuery();
  const materials = query.get("materials");

  if (clientId) {
    LogRocket.identify(clientId);
  }

  //get material details based on materials query param
  const materialDetails = data?.fields?.associatedMaterialsIfRequired?.find(
    (associatedMaterial) => {
      const findUploadedMaterialByMaterialQueryParamId =
        associatedMaterial?.fields?.uploadMaterial.find((uploadedMaterial) => {
          return uploadedMaterial.sys.id === materials;
        });
      return findUploadedMaterialByMaterialQueryParamId;
    }
  );

  const fileWatcher = useWatch({ control, name: "file" });

  useEffect(() => {
    const token = process.env.REACT_APP_MIXPANEL_TOKEN;
    if (!token) throw new Error("No Mixpanel token found");

    mixpanel.init(token, {
      debug: true,
    });
    mixpanel.track("Viewed Home Program", {
      homeProgramId: homeworkId,
      homeworkType: "expressable-academy",
    });
  }, []);

  useEffect(() => {
    if (submissionError)
      addToast(submissionError, { appearance: "error", autoDismiss: true });
    LogRocket.captureException(submissionError as unknown as Error);
    if (submitted) history.push("/thankyou");
  }, [submissionError, isSubmittingHomework, submitted]);

  if (loadingData) {
    return (
      <div className="flex-1 text-center py-20">
        <Loader type="ring" />
      </div>
    );
  }

  if (error) {
    return (
      <BackgroundImage className="md:px-28 sm:px-20">
        <div className="md:py-8 py-4 sm: w-11/12 m-auto md:m-0 md:w-1/2">
          <Link className="w-32" to="/">
            <img
              className="w-32"
              src="https://ik.imagekit.io/expressable/expressable-white_O49K43BeY.svg"
              alt="Expressable Logo"
            />
          </Link>
          <p className="sm:w-full md:text-4xl text-2xl md:w-3/5 mb-10 text-white font-domine md:py-28 leading-snug mb-0 pt-12 pb-4">
            {error}
          </p>
        </div>
      </BackgroundImage>
    );
  }
  const toggleShow = (state: string) => {
    if (state == "showWhatToLook") {
      setShowWhatToLook(!showWhatToLook);
    } else {
      setShowWhatToDo(!showWhatToDo);
    }
  };

  const onSubmitHomework = async (dataForm: IHomeWorkSubmissionForm) => {
    await submitExpressableHomework(dataForm, data);
  };

  return (
    <HomeworkStyled className="font-poppins leading-8">
      <Helmet defaultTitle={data?.fields["title"]} />
      <BackgroundImage className="md:px-28 sm:px-20 test">
        <div className="md:py-8 py-4  m-auto md:m-0 md:w-1/2 w-11/12">
          <Link className="w-32" to="/">
            <img
              className="w-32"
              src={expressableLogo}
              alt="Expressable Logo"
            />
          </Link>
          <h3 className="sm:w-full md:text-40px text-2xl md:w-3/5 mb-10 text-white font-domine md:py-28 leading-normal pt-12 pb-4 font-normal tracking-normal">
            {data?.fields["title"]}
          </h3>
        </div>
        <div className="md:pt-16 sm:pt-0 right-0 sm:relative md:relative block sm:w-full md:w-1/2 ">
          <Card className="md:w-3/4 w-full m-auto p-0 md:rounded-lg sm:rounded-none rounded-none">
            {data?.fields?.videoFile?.length &&
              data?.fields?.videoFile[0]?.fields?.file?.url && (
                <video style={{ height: "100%", width: "100%" }} controls>
                  <source src={data?.fields?.videoFile[0]?.fields?.file?.url} />
                </video>
              )}
            <div className="p-6">
              <div className="mt-4 text-gray-500 font-medium text-sm">
                TIME REQUIRED
              </div>
              <div className="text-indigo-700 text-2xl font-bold mb-8 leading-normal tracking-normal ">
                {data?.fields.timeRequired}
              </div>
              <div className="mt-8 text-gray-500 font-medium text-sm">
                HOMEWORK GOALS
              </div>
              <div className="mt-2 text-gray-500">
                {data?.fields?.homeworkGoals &&
                  documentToReactComponents(data?.fields?.homeworkGoals)}
              </div>
            </div>
          </Card>
        </div>
      </BackgroundImage>
      <div className="md:px-28 sm:px-20 max-w-7xl">
        <div className="md:px-0 sm:px-8 px-8 flex text-gray-800 w-full md:w-full sm:bg-white bg-white md:m-0 m-auto homework">
          <div className="inline-block md:w-1/2 w-full">
            <div>
              <div className="pt-24">
                <div
                  data-testid="homework-head"
                  className="font-semibold text-2xl mb-4 leading-normal tracking-normal	"
                >
                  Activity Overview
                </div>
                <p data-testid="homework-description" className="mb-8">
                  {data?.fields["aboutThisActivity"]}
                </p>
                {materialDetails && (
                  <div
                    onClick={() =>
                      download(
                        materialDetails?.fields?.uploadMaterial![0]?.fields.file
                          .url
                      )
                    }
                    className="flex flex-row cursor-pointer underline"
                  >
                    <img src={downloadLogo} className="text-indigo-700 mr-3" />
                    <a className="text-indigo-500 font-extrabold justify-items-start items-start ">
                      Download {materialDetails.fields.nameOfMaterial}
                    </a>
                  </div>
                )}
              </div>
              <hr className="my-10" />
              {data?.fields?.whatYoullNeed && (
                <div className="mb-8">
                  <div
                    data-testid="homework-required-items"
                    className="font-semibold text-2xl mb-4 leading-normal tracking-normal"
                  >
                    What You&lsquo;ll Need
                  </div>
                  <WhatYoullNeedStyled>
                    {data?.fields?.whatYoullNeed &&
                      documentToReactComponents(data?.fields?.whatYoullNeed)}
                  </WhatYoullNeedStyled>
                </div>
              )}
            </div>
            <hr className="my-10" />
            <div>
              {/* TODO since there can be nth steps, we should create a step list components   */}
              <div
                data-testid="homework-guideline"
                className="font-semibold text-2xl mb-10 leading-normal tracking-normal"
              >
                Activity Guidelines
              </div>
              <div className="mb-8">
                <div className="text-gray-400 text-xs font-medium ">STEP 1</div>
                <div className="font-bold my-4 leading-normal tracking-normal">
                  {data?.fields["step1Title"] || ""}
                </div>
                <p className="whitespace-pre-line">
                  {data?.fields["step1Description"]}
                </p>
              </div>
              <div className="mb-8">
                <div className="text-gray-400 text-xs font-medium">STEP 2</div>
                <div className="font-bold my-4 leading-normal tracking-normal">
                  {data?.fields["step2Title"] || ""}
                </div>
                <p className="whitespace-pre-line">
                  {data?.fields["step2Description"]}
                </p>
              </div>
              {data?.fields["step3Title"] && (
                <div className="mb-8">
                  <div className="text-gray-400 text-xs font-medium">
                    STEP 3
                  </div>
                  <div className="font-bold my-4 leading-normal tracking-normal">
                    {data?.fields["step3Title"] || ""}
                  </div>
                  <p className="whitespace-pre-line">
                    {data?.fields["step3Description"]}
                  </p>
                </div>
              )}
              {data?.fields["step4Title"] && (
                <div className="mb-8">
                  <div className="text-gray-400 text-xs font-medium">
                    STEP 4
                  </div>
                  <div className="font-bold my-4 leading-normal tracking-normal">
                    {data?.fields["step4Title"] || ""}
                  </div>
                  <p>{data?.fields["step3Description"]}</p>
                </div>
              )}
              {data?.fields["step5Title"] && (
                <div className="mb-8">
                  <div className="text-gray-400 text-xs font-medium">
                    STEP 5
                  </div>
                  <div className="font-bold my-4 leading-normal tracking-normal">
                    {data?.fields["step5Title"] || "Title placeholder"}
                  </div>
                  <p>{data?.fields["step3Description"]}</p>
                </div>
              )}
            </div>
            {data?.fields?.whatToLookForInChildsActions && (
              <div className="mb-8">
                <div
                  className="flex font-bold text-indigo-700 underline  leading-4 cursor-pointer"
                  onClick={() => toggleShow("showWhatToLook")}
                >
                  What to Look for During the Activity
                  <div className="flex items-end ">
                    {!showWhatToLook ? (
                      <FontAwesomeIcon
                        className="text-xl text-indigo-700 items-start flex ml-1 "
                        icon={faChevronDown}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="text-xl text-indigo-700 flex items-start ml-1"
                        icon={faChevronUp}
                      />
                    )}
                    <div />
                  </div>
                </div>

                <div
                  className={`max-h-0 overflow-hidden transition-all duration-500 ${
                    showWhatToLook ? "max-h-96 pt-8 pb-4" : ""
                  }`}
                >
                  {documentToReactComponents(
                    data?.fields?.whatToLookForInChildsActions
                  )}
                </div>
                <hr className="my-10" />
              </div>
            )}
            {data?.fields?.whatToDoIfYourChildGetsStuck && (
              <div className="mb-4">
                <div
                  className="font-bold text-indigo-700 underline flex leading-4 cursor-pointer"
                  onClick={() => toggleShow("showWhatToDo")}
                >
                  What to Do if This Feels Tough
                  <div className="flex items-end ">
                    {!showWhatToDo ? (
                      <FontAwesomeIcon
                        className="text-xl text-indigo-700 flex items-end ml-1"
                        icon={faChevronDown}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="text-xl text-indigo-700 flex items-end ml-1"
                        icon={faChevronUp}
                      />
                    )}
                  </div>
                </div>
                <div
                  className={`max-h-0 overflow-hidden transition-all duration-500 ${
                    showWhatToDo ? "max-h-96 pt-8 pb-4" : ""
                  }`}
                >
                  {documentToReactComponents(
                    data?.fields?.whatToDoIfYourChildGetsStuck
                  )}
                </div>
                <hr className="mt-10" />
              </div>
            )}
            <form onSubmit={handleSubmit(onSubmitHomework)}>
              <div
                data-testid="homework-first-input-head"
                className="flex mt-10 mb-2"
              >
                <h4 className="text-sm font-semibold">{`Describe any success when practicing this activity.`}</h4>
              </div>
              <Textarea
                {...register("success", {
                  required: "Please, fill out this field",
                })}
                data-testid="success"
                className="w-full"
                rows={4}
                error={errors.success}
              />

              <div
                data-testid="homework-second-input-head"
                className="flex mt-10 mb-2"
              >
                <h4 className="text-sm font-semibold">
                  What was difficult when practicing this activity?
                </h4>
              </div>
              <Textarea
                {...register("difficulty", {
                  required: "Please, fill out this field",
                })}
                data-testid="difficulty"
                className="w-full"
                error={errors.difficulty}
                rows={4}
              />

              <div
                data-testid="homework-third-input-head"
                className="flex mt-10 mb-2"
              >
                <h4 className="text-sm font-semibold">{`Please attach a file of you participating in this activity - like a video or audio clip (optional)`}</h4>
              </div>
              <div className="flex w-full items-center mb-2">
                <Label className="w-full flex flex-col items-center px-4 py-6 bg-white text-indigo-500 rounded-lg tracking-wide border border-dashed border-gray-default cursor-pointer ">
                  <img
                    src={uploadLogo}
                    alt="upload logo"
                    className="h-12 w-12"
                  />
                  <span className="mt-2 text-base leading-normal">
                    Upload File
                  </span>
                  <Input
                    {...register("file")}
                    accept="image/jpeg, image/png, image/jpg, audio/aac, audio/amr, audio/mpeg, audio/x-m4a, video/mp4, video/mpeg, video/quicktime, video/x-msvideo, video/x-ms-wmv, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    type="file"
                    name="file"
                    data-testid="file"
                    className="w-full mb-2 hidden"
                  />
                </Label>
              </div>
              <h4
                data-testid="homework-attachment-sub-head"
                className="text-sm text-gray-400 mb-12"
              >
                This attachment will only be viewed by your speech therapist
              </h4>
              {fileWatcher?.length && (
                <FileViewerRow
                  fileWatcher={fileWatcher}
                  onRemoveFile={() => setValue("file", undefined)}
                />
              )}

              <div className="flex w-full flex-col lg:flex-row">
                <div className="flex w-full lg:w-1/2">
                  <Button
                    disabled={!isValid || isSubmittingHomework}
                    loading={isSubmittingHomework}
                    variant="primary"
                    data-testid="submit-homework"
                    className="bg-indigo-600 text-white mb-10 flex-1 leading-normal tracking-normal"
                    type="submit"
                    disabledClassName="bg-indigo-200"
                  >
                    Submit To Your Therapist
                  </Button>
                </div>

                <div
                  data-testid="homework-hipaa"
                  className="flex lg:w-1/2 sm:ml-3 justify-center  w-full md:justify-end"
                >
                  <img
                    src={hippaLogo}
                    alt="HIPAA"
                    style={{ width: "104px", height: "37px" }}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </HomeworkStyled>
  );
}
export default Homework;
